<div class="page-container" [class.mobile]="mobile">
  <mat-toolbar color="primary" class="toolbar">
    <div class="navigation">
      <button class="menu" mat-icon-button (click)="nav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a class="logo" routerLink="/dashboard">ETHorama Admin</a>
    </div>
    <div class="user" [matMenuTriggerFor]="menu">
      <button mat-icon-button *ngIf="mobile">
        <mat-icon>account_circle</mat-icon>
      </button>
      <button mat-button *ngIf="!mobile">
        <mat-icon>account_circle</mat-icon>
        <span class="name" *ngIf="user">{{ user.firstName }} {{ user.lastName }}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/users/{{ user?.id }}">
          <mat-icon>settings</mat-icon>
          <span>Einstellungen</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Abmelden</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #nav class="sidenav" [mode]="mobile ? 'over' : 'side'"
                 [opened]="!mobile" [fixedInViewport]="mobile" fixedTopGap="56">
      <mat-nav-list>
        <a class="list-item" mat-list-item routerLink="/dashboard" routerLinkActive="active">
          <mat-icon>space_dashboard</mat-icon>
          Dashboard
        </a>
        <a class="list-item" mat-list-item routerLink="/pois" routerLinkActive="active">
          <mat-icon>place</mat-icon>
          Points of Interest
        </a>
        <a class="list-item" mat-list-item routerLink="/content-items" routerLinkActive="active">
          <mat-icon>category</mat-icon>
          Content Items
        </a>
        <a class="list-item" mat-list-item routerLink="/dossiers" routerLinkActive="active">
          <mat-icon>folder</mat-icon>
          Dossiers
        </a>
        <a class="list-item" mat-list-item routerLink="/routes" routerLinkActive="active">
          <mat-icon>route</mat-icon>
          Routen
        </a>
        <a class="list-item" mat-list-item routerLink="/users" routerLinkActive="active">
          <mat-icon>people</mat-icon>
          Benutzer
        </a>
        <div class="footer">
          v{{ version }}, developed by <a href="https://devedis.ch/" target="_blank" rel="noopener">devedis</a>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content #wrapper class="content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
