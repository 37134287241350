import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../auth/services/login.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserDto } from '../../../user/dtos/user.dto';
import { PageStateService } from '../../services/page-state.service';
import { MatSidenavContent } from '@angular/material/sidenav';
import appInfo from '../../../../../package.json';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  version = appInfo.version;

  private mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  @ViewChild('wrapper') wrapper: MatSidenavContent;

  constructor(
    private loginService: LoginService,
    private media: MediaMatcher,
    private cd: ChangeDetectorRef,
    private pageStateService: PageStateService
  ) { }

  ngOnInit() {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => this.cd.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  ngAfterViewInit() {
    this.pageStateService.scrollContainer = this.wrapper.getElementRef().nativeElement;
  }

  ngOnDestroy() {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }

  async logout() {
    await this.loginService.logout();
  }

  get user(): UserDto | undefined {
    return this.loginService.user;
  }

  get mobile(): boolean {
    return this.mobileQuery.matches;
  }

}
