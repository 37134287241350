import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from "./core/components/page-layout/page-layout.component";
import { AuthGuard } from "./auth/guards/auth.guard";

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'pois',
        loadChildren: () => import('./poi/poi.module').then(m => m.PoiModule)
      },
      {
        path: 'content-items',
        loadChildren: () => import('./content-item/content-item.module').then(m => m.ContentItemModule)
      },
      {
        path: 'dossiers',
        loadChildren: () => import('./dossier/dossier.module').then(m => m.DossierModule)
      },
      {
        path: 'routes',
        loadChildren: () => import('./route/route.module').then(m => m.RouteModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'not-found',
        loadChildren: () => import('./core/pages/not-found/not-found-page.module').then(m => m.NotFoundPageModule)
      },
      {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
