// TODO: Replace with shared DTOs
export class UserDto {

  id: string;

  email: string;

  firstName: string;

  lastName: string;

}
