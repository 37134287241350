import { Type } from 'class-transformer';
import { UserDto } from '../../user/dtos/user.dto';

// TODO: Replace with shared DTOs
export class LoginResponseDto {

  jwt: string;

  @Type(() => UserDto)
  user: UserDto;

}
