import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from "rxjs";
import { LoginResponseDto } from '../dtos/login-response.dto';
import { plainToClass } from 'class-transformer';
import { UserDto } from '../../user/dtos/user.dto';

// TODO: Rename to AuthService?
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly endpoint: string = 'auth/login';

  private data: LoginResponseDto | null; // TODO: Split into two objects?
  private storageKey = 'auth';

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected activeRoute: ActivatedRoute
    ) {
    this.load();
  }

  async login(email : string, password : string): Promise<LoginResponseDto> {
    const request = this.http.post(`${environment.apiUrl}/${this.endpoint}`, { 'email' : email, 'pw': password });
    const dto = await firstValueFrom(request);
    const data = plainToClass(LoginResponseDto, dto);
    this.save(data);
    return data;
  }

  async logout() {
    this.delete();
    await this.router.navigate(['/login']);
  }

  private save(data: LoginResponseDto) {
    this.data = data;
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  private load() {
    const data = localStorage.getItem(this.storageKey);
    this.data = data ? plainToClass(LoginResponseDto, JSON.parse(data)) : null;
  }

  private delete() {
    this.data = null;
    localStorage.removeItem(this.storageKey);
  }

  get isLoggedIn(): boolean {
    return !!this.data;
  }

  get user(): UserDto | undefined {
    return this.data?.user;
  }

  get token(): string | undefined {
    return this.data?.jwt;
  }
}
